import * as React from "react";
import LogoSolucao from "../../themes/solucao/logosolucao.png";
import LogoOpcao from "../../themes/opcao/logoopcao.png";
import LogoMaxi from "../../themes/maxi/logo.png";

interface SVGIconProps {
  width: string | number;
  color: string;
  name: string;
}

const LogoIcon: React.FC<SVGIconProps> = ({ width, color, name }) => {
  const [element, setElement] = React.useState<JSX.Element | null>(null);

  React.useEffect(() => {
    let whiteLabelLogo = process.env.REACT_APP_WHITE_LABEL ?? 'nova';
    if(whiteLabelLogo === 'maxi') {
      setElement(<img src={LogoMaxi} alt={"logo"}/>);
    } if(whiteLabelLogo === 'solucao') {
      setElement(<img src={LogoSolucao} alt={"logo"}/>);
    } else if(whiteLabelLogo === 'opcao') {
      setElement(<img src={LogoOpcao} alt={"logo"}/>);
    } else if(whiteLabelLogo === 'nova') {
      import( `../../themes/nova/logo.svg`).then((res) => {
        const Icon = res.ReactComponent as React.ComponentType<JSX.IntrinsicElements['svg']>;
        setElement(<Icon/>);
      });
    }
  }, [name, color, width]);

  return element;
};

export default LogoIcon;
