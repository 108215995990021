// src/polyfills.ts

// Extende a interface String para incluir o método replaceAll
declare global {
  interface String {
    replaceAll(search: string | RegExp, replacement: string): string;
  }
}

// Polyfill para replaceAll: se o método não existir, ele é criado.
if (!String.prototype.replaceAll) {
  // @ts-ignore
  String.prototype.replaceAll = function (search: string | RegExp, replacement: string): string {
    return this.split(search).join(replacement);
  };
}

export {};
