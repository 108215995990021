import {ShippingService} from '../../ts/interfaces/Shipping';
import {formatMoneyMask} from '../../helper/FormatFields';
import shippingArrow from '../../assets/icons/shipping-arrow.svg';

import './styles.scss';

interface ShippingServiceProps {
  data: ShippingService;
  shippingSelected: boolean;
  onSelectShippingService: (price: number) => void;
}

export default function ShippingServicesList({
                                               data,
                                               shippingSelected,
                                               onSelectShippingService
                                             }: ShippingServiceProps) {

  const carrierName = data.carrier_name === data.name ? '' : data.carrier_name;

  return (
    <div className="shipping-services-container">
      <div
        className={`checkbox ${shippingSelected ? 'selected' : 'not-selected'}`}
        onClick={() => onSelectShippingService(data.price)}
      >
        <span>✔</span>
      </div>

      <div className='details'>
        <span>{carrierName} ({data.name}) - Prazo de entrega {data.days} úteis após a confirmação do pagamento e da postagem do produto</span>
        <span className='price'>
          <img className='arrow-icon' src={shippingArrow} alt="arrow" height={8} width={8}/>
          {data.discount > 0 ? <>
            <span className={"old-price"}>{formatMoneyMask(data.price.toString())}</span>
            <span className={"new-price"}> {formatMoneyMask((data.price - data.discount).toString())}</span>
          </> : <>
            <span className={"new-price"}>{formatMoneyMask(data.price.toString())}</span>
          </>}
        </span>
      </div>
    </div>
  )
}
